body {
  margin: 0;
  font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400 !important;
  color: #313949 !important;
}
code {
  font-family: 'Lato', sans-serif, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace !important;
}


.rst__moveHandle{
  background-color: white !important;
  background:  url(./../public/folder_icon.svg) no-repeat !important;
  background-position: 50% 50% !important;
  background-size: 30px 30px !important;
  
}


.rst__moveHandle{
  border: none !important;
  box-shadow: none !important;
}

.rst__rowContents{
  border: none !important;
  box-shadow: none !important;
}

.rst__row{
  border: 1px solid #aaa;
}

#viewer-container {
  /* height: 575px !important; */
  /* width: 99% !important; */
  height: 100vh;
  display: flex;
  justify-content: center;
}


.react-contextmenu-item {
  padding: 6px 15px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.react-contextmenu-item span {
  font-weight: 400 !important;
  padding-left: 5px !important;
}
.RiPencilLine {
  font-size: 20px !important;
  margin-left: 7px !important;
}
.delete {
  font-size: 20px !important;
  margin-left: 7px !important;
}

#pagelist-container{
  height: 480px !important;
}


.relatedlist-files-container{
  padding-top: 7px !important;
}


/* #basic-menu{
  background-color: red;
} */