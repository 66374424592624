.moduleBtn {
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.activeModuleBtn {
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1.5px solid #1f6dde;
  border-radius: 0.9rem;
  color: #1976d2;
  font-weight: 600;
  /* cursor: pointer; */
}
.nonactiveModuleBtn {
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1.5px solid transparent;
  border-radius: 0.9rem;
  color: #1976d2;
  font-weight: 600;
  /* cursor: pointer; */
}

.activeModuleBtnNew {
  background-color: white;
  padding: 0.5rem 1rem;
  border: 1px solid #1f6dde;
  border-radius: 0.9rem;
  /* cursor: pointer; */
}

.selectedFolder {
  background-color: #eaf4ff;
  border: 1px solid #1f6dde;
  color: #1f6dde;
}

/* .sharedPointCss {
  background-color: rgb(53, 244, 94);
  padding: 0.5rem 1rem;
  border: 1px solid #ffffff;
  border-radius: 0.9rem;
  /* cursor: pointer; */
/* } */

.nonSharedPoint {
  color: #ef5350;
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  font-weight: 600;
  border-radius: 0.9rem;
  /* cursor: pointer; */
}

.nonSharedPointActive {
  color: #ef5350;
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1.5px solid #ef5350;
  font-weight: 600;
  border-radius: 0.9rem;
  /* cursor: pointer; */
}
